<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import btnComponent from "@/components/btnComponent";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ลูกค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    btnComponent,
  },
  data() {
    return {
      title: "ลูกค้า",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
        },
        {
          text: "ข้อมูลลูกค้า",
          active: true,
        },
      ],
      localDataBranch: [], //เป็น-array Id
      localData: [],

      cusType: [
        {
          cusType: "P",
          CustypeName: "บุคคล",
        },
        {
          cusType: "B",
          CustypeName: "นิติบุคคล",
        },
      ],
      CusType: "",
      CustypeName: "",
      cusId: this.$route.params.cusId,

      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      // rowData: [], //? Data ที่ต่อ API
      rowUser: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: [
        {
          nameTh: "",
          familyNameTh: "",
          mobilePhone: "",
        },
      ],
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [
        {
          key: "cusId",
          label: "รหัสลูกค้า",
        },
        {
          key: "cusType",
          sortable: true,
          label: "ประเภทลูกค้า",
        },
        {
          key: "nameTh",
          sortable: true,
          label: "ชื่อ",
        },
        {
          key: "familyNameTh",
          sortable: true,
          label: "นามสกุล",
        },
        {
          key: "mobilePhone",
          sortable: true,
          label: "โทรศัพท์",
        },
        {
          key: "email",
          sortable: true,
          label: "อีเมล",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowData.length;
    },
    //
  },
  mounted() {
    // Set the initial number of items
    this.rowUser = this.items.length;
  },

  created() {
    this.getLocalData();
    this.getData();
    // CustypeChange();
    this.startIndex = 1;
    this.endIndex = this.perPage;
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
      // console.log(this.localDataBranch);
    },
    getData: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranch,
            nameTh: this.filter.nameTh,
            familyNameTh: this.filter.familyNameTh,
            mobilePhone: this.filter.mobilePhone,
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.paginatedData = this.rowUser.slice(
            this.startIndex,
            this.endIndex
          );
          // );
          // this.CustypeChange();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    handleSearch() {
      this.getData();
    },

    ConfirmDelUser(rowUser) {
      Swal.fire({
        title: "ต้องการลบรายการนี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.deleteUser(rowUser);
        }
      });
    },
    deleteUser: function(rowUser) {
      this.userData = rowUser;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/customer/delete", {
          data: {
            cusId: this.userData,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
          this.getData();
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowUser.slice(this.startIndex, this.endIndex);
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowUser.slice(this.startIndex, this.endIndex);
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    CustypeChange() {
      if (this.rowUser.cusType === "P") {
        this.CustypeName = "บุคคล";
      } else {
        this.CustypeName = "นิติบุคคล";
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <!-- <h4 class="card-title">ข้อมูลลูกค้า</h4> -->

              <br />
              <div class="row">
                
                <div class="col-12 col-sm-4 col-md-2">
                  <label class="d-inline align-items-center m-2 text-end">
                    ชื่อจริง:
                    <b-form-input
                      v-model="filter.nameTh"
                      type="search"
                      placeholder="ชื่อจริง/ชื่อนิติบุคคล"
                      class="form-control"
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                  </label>
                </div>
                <div class="col-12 col-sm-4 col-md-2">
                  <label class="d-inline align-items-center m-2">
                    นามสกุล:
                    <b-form-input
                      v-model="filter.familyNameTh"
                      type="search"
                      placeholder="นามสกุล"
                      class="form-control"
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                  </label>
                </div>
                <div class="col-12 col-sm-4 col-md-2">
                  <label class="d-inline align-items-center m-2">
                    เบอร์โทรศัพท์:
                    <b-form-input
                      v-model="filter.mobilePhone"
                      type="search"
                      placeholder="เบอร์โทรศัพท์"
                      class="form-control "
                      @keyup.enter="handleSearch"
                    ></b-form-input>
                  </label>
                </div>
                <div class="col-md-3"></div>
                <div class="col-auto text-end">
                  <label class="d-inline align-items-center">
                    &nbsp;<br />
                    <btnComponent @click="handleSearch" changeStyle="search">
                    </btnComponent>
                  </label>
                </div>
                <div class="col-auto text-end">
                  <label class="d-inline align-items-center">
                    &nbsp;<br />
                    <router-link :to="{ name: 'formcus' }">
                      <button class="btn btn-primary"><i class="mdi mdi-plus"></i> เพิ่ม</button>
                    </router-link>
                  </label>
                </div>
              </div>
              <div class="row">
                <!-- Search -->
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-block align-items-center">
                          แสดงผล &nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp; รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>

              <!-- Table -->

              <div class="table-responsive mb-0">
                <b-table
                  v-if="rowUser && rowUser.length > 0 && !loading"
                  id="table-transition-userList"
                  :key="rowUser.userId"
                  :items="rowUser"
                  :fields="fields"
                  responsive="true"
                  :per-page="perPage"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :select-mode="selectMode"
                  ref="selectableTable"
                  
                  @row-selected="onRowSelected"
                >

                  <template #cell(cusType)="rowUser">
                    <span v-if="rowUser.item.cusType === 'P'">
                      บุคคล
                    </span>
                    <span v-if="rowUser.item.cusType === 'B'">
                      นิติบุคคล
                    </span>
                  </template>
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowUser">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'formcus-edit',
                        params: { cusId: rowUser.item.cusId },
                      }"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </router-link>

                    <a
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="ConfirmDelUser(rowUser.item.cusId)"
                    >
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a>
                  </template>

                  <!-- End edit table -->
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  แสดงผล {{ startIndex }} ถึง {{ endIndex }} จาก
                  {{ totalRecord }} รายการ
                </div>

                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecord"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
                <br />
                <hr />
                <br />
                <!-- <p>
                  <b-button size="sm" @click="selectAllRows"
                    >เลือกทั้งหมด</b-button
                  >
                  |
                  <b-button size="sm" @click="clearSelected"
                    >ล้างการเลือก</b-button
                  >
                </p> -->
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- Test push
              <button
                    class="popup-form btn btn-success "
                    href="javascript: void(0);"
                    v-b-modal.modal-1
                    >เพิ่มที่อยู่</button
              >
              
              <b-modal id="modal-1" title="POST TEST" hide-footer size="lg" centered>
                <div class="card-body">
                  <form class="needs-validation" @submit.prevent="Testpost">

                    <div class="row">

                      <div class="col-lg-6">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="validation">name</label>
                            <input
                            
                            v-model="testpost.name"
                            type="text"
                            class="form-control"
                            placeholder="บ้านเลขที่"
                            :class="{
                                'is-invalid': submitform && $v.Testpost.name.$error,
                            }"
                        />
                            <div
                            v-if="submitform && $v.Testpost.name.$error"
                            class="invalid-tooltip"
                            >
                            <span v-if="!$v.Testpost.name.required"
                                >{{ error }}</span
                            >
                            </div>
                        </div>

                        <div class="col-lg-6">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="validation">Job</label>
                            <input
                            
                            v-model="testpost.job"
                            type="text"
                            class="form-control"
                            placeholder="บ้านเลขที่"
                            :class="{
                                'is-invalid': submitform && $v.Testpost.job.$error,
                            }"
                        />
                            <div
                            v-if="submitform && $v.Testpost.job.$error"
                            class="invalid-tooltip"
                            >
                            <span v-if="!$v.Testpost.job.required"
                                >{{ error }}</span
                            >
                            </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
    </b-modal> -->
  </Layout>
</template>
